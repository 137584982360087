.container {
  padding: 12px;

  .title {
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 1.3rem;
    line-height: 1;
    display: inline-block;

    transition: color 600ms ease;
  }

  .graph {
    background: var(--c-background-200);
    max-width: 140px;
    height: 2px;
    position: relative;

    transition: background-color 600ms ease;

    .active {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      background: var(--c-candy-red);

      transition: background-color 600ms ease;
    }
  }
}

.button {
  background: var(--c-background-100);
  padding: 12px;
  height: 160px;

  transition: background-color 600ms ease;

  overflow: hidden;
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    fill: var(--c-element-100);
    width: 100px;
    z-index: 1;
    transition: transform 300ms ease-in-out, color 300ms ease-in-out;
  }

  &::after {
    content: '';
    background: var(--c-candy-red);

    width: 100%;
    height: 320px;
    position: absolute;
    bottom: -320px;
    left: 0;

    filter: brightness(1.2);
    transform: rotate(-540deg);
    transition: transform 300ms ease-in-out;
  }

  &:hover {
    svg {
      transform: scale(1.2);
      fill: var(--c-polar-white);
    }

    &::after {
      transform: rotate(0) scale(2.2);
    }
  }
}

.section {
  max-width: 960px;
  margin: 220px auto;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 6px;

  padding-left: env(safe-area-inset-left);
  padding-right: env(safe-area-inset-right);

  @media (min-width: 768px) {
    grid-template-columns: repeat(4, 1fr);
    gap: 16px;
  }
}

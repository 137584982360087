.link {
  border: 4px solid var(--c-candy-red);
  background: var(--c-candy-red);

  color: var(--c-polar-white);
  text-transform: uppercase;
  letter-spacing: 3px;
  line-height: 1;

  padding: 8px 24px;

  transition: 
    transform 300ms ease-in-out,
    bottom 300ms ease-in-out,
    right 300ms ease-in-out,
    font-size 300ms ease-in-out;

  &:hover,
  &:focus { 
    transform: rotate(-7deg) scale(2);
    bottom: 6px;
    right: 40px;
    font-size: 10px;
   }

   svg {
     stroke: var(--c-polar-white);
     width: 22px;
     height: 22px;
     transition: transform 200ms ease-in-out;
   }

   &.icon { 
     padding: 8px;

     display: flex;
     align-items: center;
     justify-content: center;

     &:hover,
     &:focus { 
       transform: rotate(-7deg) scale(1.5);

       svg { transform: scale(1.5) }
      }
    }
}

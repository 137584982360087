.section {
  width: 100%;
  max-width: 96rem;
  margin: 24rem auto;
  padding-left: calc(env(safe-area-inset-left) + 2rem);
  padding-right: calc(env(safe-area-inset-right) + 2rem);

  display: flex;
  align-items: center;
  justify-content: center;

  .card {
    @media screen and (min-width: 1024px) {
      border-radius: 2.8rem;
      border-bottom-right-radius: 0;
    }

    .cardBody {
      h2 {
        margin-bottom: 1.6rem;
      }

      & > div {
        margin-top: 0.8rem;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 0.8rem;
      }
    }

    .cardImage {
      background: var(--c-element-200);
      display: flex;
      align-items: center;
      justify-content: center;

      transition: background-color 600ms ease;

      svg {
        width: 20rem;
        height: 19rem;
        fill: var(--c-background-100);

        transition: fill 600ms ease;
      }
    }

    .button {
      position: absolute;
      bottom: 0;
      right: 0;
    }
  }
}

.section {
  width: 100vw;
  max-width: 96rem;
  margin: 12rem auto;
  padding: 4.8rem 0;
  padding-left: calc(env(safe-area-inset-left) + 2rem);
  padding-right: calc(env(safe-area-inset-right) + 2rem);

  h2 {
    margin-bottom: 24px;
    text-align: center;
  }
}
